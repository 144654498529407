import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import uuid from 'uuid';

import("./style.sass")

const FeaturedActivities = ({activities}) => (
  <section className="featured-activities">
    <div className="inner-content">
      <div className="row reverse-cols">
        <div className="col-xl-5">
          <div className="mb-4">

            <h2 className="section-title">Activities</h2>

            <p className="section-intro">
              Discover the Sahara desert Without Limits!
            </p>

            <p className="section-text">
              Merzouga desert is a great place to visit as you can do many exciting activities like Buggy Tours, Camel Ride, Sandboard ...  
            </p>

            <Link className="read-more arrow-link" to="/activities">More Activities <span>⟶</span></Link>

          </div>

        </div>

        <div className="col-xl-7">
          <div className="row">

            {activities.map(({node}) => (

              <div className="col-sm-4" key={uuid.v4()}>
                <div className="activity">
                  <Link to={node.frontmatter.path}>
                    <div className="activity-pic">
                      <Img sizes={node.frontmatter.thumb.childImageSharp.sizes} />
                    </div>
                    <h3>{node.frontmatter.title}</h3>
                    <div className="activity-price">{node.frontmatter.price} / {node.frontmatter.duration}</div>
                  </Link>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div> 
    </div>
  </section>

)

export default FeaturedActivities