import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import MainSlider from "../components/Home/MainSlider"
import FeaturedRooms from "../components/Home/FeaturedRooms"
import FoodDrinks from "../components/Home/FoodDrinks"
import DesertCamp from "../components/Home/DesertCamp"
import FeaturedActivities from "../components/Home/FeaturedActivities"
import History from "../components/Home/History"
import BookingForm from "../components/BookingForm"

const IndexPage = ({data}) => {

  const [loadSlider, setLoadSlider] = useState(0)

  const suites_rooms =  data.featuredRooms.edges.concat(data.featuredSuites.edges)

  setTimeout(() => setLoadSlider(1), 200)

  return (

    <Layout sidebarComponent={<BookingForm colWidth="col-md-12"/>}>

      <SEO
        title="Luxury accommodation in Merzouga, Morocco"
        description="Welcome to a spectacular desert paradise. Located in the middle of the dunes, the Riad Chebbi is an ideal choice for those who want to explore the Sahara desert of Morocco "
      />

      { (loadSlider && data.slides.edges.length) && <MainSlider slides={data.slides.edges} /> }

      { suites_rooms.length && <FeaturedRooms rooms={suites_rooms} /> }
      
      <DesertCamp />
      
      <FoodDrinks />

      { data.featuredActivities.edges.length && <FeaturedActivities activities={data.featuredActivities.edges} /> }

      <History />

    </Layout>
    
  )
}


export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    slides: 
      allMarkdownRemark (
        filter: {
          frontmatter: {type: {eq: "slide"}}
        }
        sort: { order: DESC, fields: [frontmatter___date]}
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              type
              description
              path
              image {
                childImageSharp{
                  sizes(maxWidth: 1610, maxHeight: 805) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      }

      featuredRooms: 
      allMarkdownRemark (
        limit: 2
        filter: {
          frontmatter: {type: {eq: "Luxury room"}}
        }
        sort: { order: DESC, fields: [frontmatter___date]}
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              type
              path
              date
              price
              featured
              thumb {
                childImageSharp {
                  sizes(maxWidth: 670, maxHeight: 415) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      }
    
    featuredSuites: 
      allMarkdownRemark (
        limit: 2
        filter: {
          frontmatter: {type: {eq: "Luxury suite"}}
        }
        sort: { order: DESC, fields: [frontmatter___date]}
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              type
              path
              date
              price
              featured
              thumb {
                childImageSharp{
                  sizes(maxWidth: 670, maxHeight: 415) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      }

    featuredActivities: 
      allMarkdownRemark (
        limit: 3
        filter: {
          frontmatter: {
            type: {eq: "Activity"},
            featured: {eq: true}
          }
        }
        sort: { order: DESC, fields: [frontmatter___date]}
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              type
              path
              date
              featured
              price
              duration
              thumb {
                childImageSharp{
                  sizes(maxWidth: 500, maxHeight: 640) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      }
  }
`

