import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import uuid from 'uuid';

import MainFacilities from "../../MainFacilities"


import("./style.sass")

const FeaturedRooms = ({rooms}) => (
  <div className="featured-rooms">

    <div className="inner-content">

      <h2>Luxury Rooms & Suites</h2>
      
      <div className="featured-rooms-list">
        <div className="row">
          {rooms.map(({node}) => {
            return (
              <div className="col-xl-3 col-md-6" key={uuid.v4()}>
                <div className="room">
                  <Link to={node.frontmatter.path}>
                    <Img sizes={node.frontmatter.thumb.childImageSharp.sizes} />
                    <div className="room-intro">
                      <h3>{node.frontmatter.title}</h3>
                      <p>{node.frontmatter.price} <span>/ Night</span></p>
                    </div>
                  </Link>
                </div> 
              </div>
            )
          }
          )}
        </div>
      </div>

      <div className="featured-rooms-btns">
        <Link className="arrow-link" to="/luxury-rooms-merzouga" >More Luxury Rooms  <span>⟶</span></Link><Link className="arrow-link" to="/luxury-suites-merzouga" >More Luxury Suites  <span>⟶</span></Link>
      </div>

      <MainFacilities />

    </div>

  </div>
)

export default FeaturedRooms


