import React from 'react'
import { Link } from 'gatsby'

import desert_camp_1 from "../../../images/desert-camp/2.png"


const desertCamp = () => (
  <section className="food-drink row-2-cols">
    <div className="one-half">
      <div className="cover-pic small-cover-pic" style={{backgroundImage: "url('" + desert_camp_1 + "')"}}></div>
    </div>
    <div className="one-half">
      <div className="inner-content">
        <h2 className="section-title">Desert camp</h2>
        <p className="section-intro">
          Single, Double, Triple & Quadruple tents
        </p>

        <p className="section-text">
          The camp has a maximum capacity of 15 people. Each Tent has been decorated in a personalized way so that our guests feel at home in a different and idyllic place. The materials used have been made by hand in Morocco.
        </p>

        <Link
          className="read-more arrow-link"
          to="/desert-camp"
        >Read More  <span>⟶</span></Link>

      </div>
    </div>
      
  </section>

)

export default desertCamp