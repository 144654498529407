import React from 'react'
import { Link } from 'gatsby'

import food_drinks_1 from "../../../images/food-drinks-1.jpg"
import food_drinks_2 from "../../../images/food-drinks-2.jpg"

import("./style.sass")

const FoodDrinks = () => (
  <section className="food-drink row-2-cols reverse-cols">
    <div className="one-half">
      <div className="cover-pic" style={{backgroundImage: "url('" + food_drinks_1 + "')"}}></div>
      <div className="row-2-cols">
        <div className="one-half">
          <div className="cover-text">
            <div className="inner-content">
              Buffet & à la carte!
            </div>
          </div>
        </div>
        <div className="one-half">
          <div className="cover-pic small-cover-pic" style={{backgroundImage: "url('" + food_drinks_2 + "')"}}></div>
        </div>
      </div>
    </div>
    <div className="one-half">
      <div className="inner-content">
        <h2 className="section-title">Food & Drinks</h2>
        <p className="section-intro">
          Breakfast, Brunch, Lunch, Dinner, High tea, Cocktail hour
        </p>



        <p className="section-text">
          We are renowned for the cuisine in our restaurant, where we offer an innovative European-Moroccan fusion, based on local produce and flavours...
        </p>

        <Link
          className="read-more arrow-link"
          to="/food-drinks"
        >Read More  <span>⟶</span></Link>

      </div>
    </div>
      
  </section>

)

export default FoodDrinks