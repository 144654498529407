import React, { useState } from 'react'
import { Link } from 'gatsby'
import ModalVideo from 'react-modal-video'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';



import about_us_1 from "../../../images/about-us/1.jpg"
import about_us_2 from "../../../images/about-us/2.jpg"
import about_us_3 from "../../../images/about-us/3.jpg"

import("./style.sass")

const History = () => {

  const [videoIsOpen, setVideoIsOpen] = useState(false)

  return (
  
    <section className="our-history row-2-cols">
  
      <div className="one-half">
  
        <div className="inner-content">
  
          <h2 className="section-title">About <strong>Riad Chebbi</strong></h2>
  
          <p className="section-intro">A luxury accommodation in the heart of the desert!</p>
                  
          <p className="section-text">
            Welcome to a spectacular desert paradise. Located in the middle of the dunes, the Riad Chebbi is an ideal choice for those who want to explore the Sahara desert of Morocco and more specifically the desert of Merzouga. This oasis of tranquility combines rustic charm, hospitality and luxury to bring to life an authentic desert experience.
          </p>
          
          <Link
            to="/about-us"
            className="read-more arrow-link"
          >
            More about Riad Chebbi <span>⟶</span>
          </Link>
  
          <Link
            to="/contact"
            className="contact-us-btn arrow-link"
          >
            Contact us <span>⟶</span>
          </Link>
          
        </div>
  
      </div>
      
      <div className="one-half">
        <div className="video-wrapper" onClick={() => setVideoIsOpen(true)}>
          <div className="video-btn"><FontAwesomeIcon icon={faPlayCircle} /></div>
          <div className="cover-pic" style={{backgroundImage: "url('"+ about_us_1 +"')"}}></div>
          <div className="row-2-cols"> 
            <div className="one-half">
              <div className="cover-pic small-cover-pic" style={{backgroundImage: "url('"+ about_us_2 +"')"}}></div>
            </div>
            <div className="one-half">
              <div className="cover-pic small-cover-pic" style={{backgroundImage: "url('"+ about_us_3 +"')"}}></div>
            </div>
          </div>
        </div>
      </div>
  
      <ModalVideo channel='vimeo' autoplay={true} isOpen={videoIsOpen} videoId='352990995' onClose={() => setVideoIsOpen(false)} />
  
    </section>
  
  )
}

export default History



