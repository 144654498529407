import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import AliceCarousel from 'react-alice-carousel'
import uuid from 'uuid';

import("react-alice-carousel/lib/scss/alice-carousel.scss")
import("./style.sass")


const MainSlider = ({slides}) => {
  const handleOnDragStart = e => e.preventDefault()
  
  return (
    <AliceCarousel
      mouseDragEnabled
      autoPlay={true}
      autoPlayInterval={6000}
      duration={1000}
    >
      {slides.map(({node}) => (

        <div onDragStart={handleOnDragStart} key={uuid.v4()} >

          <Img sizes={node.frontmatter.image.childImageSharp.sizes} />

          <Link to={node.frontmatter.path} className="slide-content">
            <div className="slide-title">{node.frontmatter.title}</div>
            <div className="slide-description">{node.frontmatter.description}</div>
          </Link>

        </div>
        
      ))}
    </AliceCarousel>
  )

}

export default MainSlider